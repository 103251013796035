/* Constant variable */
:root {
  --blackColor: black;
  --greyColor: #181818;
}
.ant-input > *:hover{
  border:2px solid var(--primary-color);
}
.text-black-color{
  color:black;
  font-weight: 500;
}
.grey-color{
  color:#353535
  
}
.grey{
  color: #707070;
}
.ant-modal-title{
  font-family: var(--poppins-font);
  font-size: 32px;
  font-weight: 700;
  color:#353535;
  text-align: center;
}
.ant-modal-close-x {
  color: #353535 ;
  font-size: 24px;
  line-height: 70px;
}

/*Buy HaveTo coin Modal*/
.offers-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.offer-card {
  width: 48%;
  border: 1px solid #cacaca;
  border-radius: 8px;
  padding: 10px;
  transition: box-shadow 0.3s ease;
  margin-bottom: 10px;
}

.highlight {
  box-shadow: 0 0 10px rgba(24, 144, 255, 0.5);
}

.offer-icon {
  width: 40px;
  height: 40px;
}
.offer-title-box {
  background-color: #c1d8ff;
  text-align: center;
  border-radius: 4px;
  width:max-content;
  padding: 4px 8px;
  margin-bottom: 5px ;
}
.offer-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-color);
}

.offer-description {
  font-size: 12px;
  color: #8c8c8c;
}
@media (max-width: 575px) {
  .offer-card {
    width: 100%;
  }
}
/* header */
.site-layout-background {
  background: transparent;
  backdrop-filter: blur(5px) !important;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100% !important;
  height: 120px !important;
  padding: 20px;
  z-index: 5;
}
.header-right{
    width: calc(100% - 270px);
    display: flex;
    justify-content: right;
}
.header-right-collapse{
  width: calc(100% - 100px);
  display: flex;
  justify-content: right;
}
.profile-avtar{
  background-color:transparent;
  box-shadow: 0px 0px 20px 0px var(--primary-color);
  color: var(--primary-color);
  cursor: pointer;
}
.profile-avtar:hover ,.trigger:hover{
  background-color:var(--primary-color);
  color: var(--white);
}
.header-menu{
  font-family: var(--poppins-font);
  background-color:var(--white);
  min-width: 150px;
  margin:10px;
}

 
.header-menu > *{
 font-size: 16px !important;
 color:#707070;
}
.header-menu :hover{
  color: var(--primary-color);
}
.abs-header{
  height: 120px;
}

/* collapse button */
.trigger {
  color: #353535;
  font-size: 18px !important;
  position: fixed;
  left: -23px;
  border: 1px solid #CACACA;
  border-radius: 50%;
  padding: 12px;
  background-color: var(--white);
}

/* logo in sidebar */
.logo {
  padding: 30px;
  min-height: 120px !important;
}
/* sidebar */
.ant-layout-sider {
  background-color: var(--white);
  overflow: hidden;
  border: 1px  #CACACA !important;
  box-shadow:0 10px 40px rgba(0, 0, 0, 0.15) !important;
  height: 100vh;
  position:sticky;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 300px !important;
}
.buy-htc-btn{
  font-family: var(--poppins-font);
  font-size: 16px;
  margin: 20px;
  position: absolute;
  top: 80vh;
  height: 50px;
  width: 210px;
  background-color: var(--primary-color) !important;
  color:var(--white) !important;
  border:1px solid var(--primary-color) !important;
  border-radius: 25px;
}
.collapse-buy-htc-btn{
  margin: 10px;
  position: absolute;
  top: 80vh;
  height: 50px;
  width: 60px;
  background-color: var(--primary-color) !important;
  color:var(--white) !important;
  border:1px solid var(--primary-color) !important;
  border-radius: 25px;
}
.buy-htc-btn:hover,.collapse-buy-htc-btn,.download-whitepaper-btn:hover{
  background-color: transparent !important;
  color:var(--primary-color) !important;
  box-shadow: 0px 0px 10px 0px var(--primary-color);
}
/* left sidebar button */
.ant-layout-sider-trigger {
  display: none;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected  {
  background-color: var(--primary-color) !important;
  font-size: 16px;
  height: 45px;
  font-family: var(--poppins-font);
}
.display-wallet-balance{
  display: contents;
  align-items: center;
}
.convertor-card{
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.balance-transfer-cards{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
}

/* when callapsed left menu */
.ant-menu-dark.ant-menu-inline .ant-menu-item,.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  background-color: var(--white);
  height: 45px;
  margin: unset;
}

.ant-menu-dark .ant-menu-item{
  color: #8c8c8c;
  height: 45px;
}
.ant-menu-dark .ant-menu-item:hover{
  color:black;
  background-color: var(--white);
 
}
.ant-menu > * {
  font-size: 16px;
  background-color: var(--white);
  margin: 0px !important;
  font-family: var(--poppins-font);
}
/* icon style */
.ant-menu-item-icon > svg{
  font-size: 16px !important;
}

/* layout */
.ant-layout-content {
  color: white;
  font-family: var(--poppins-font);
  font-size: 16px;
}
.ant-layout{
  background-color: var(--white);
}
.ant-layout.ant-layout-has-sider > .ant-layout,.ant-layout.ant-layout-has-sider > .ant-layout-content {
  background-color: var(--white) !important;
}

/*  Dashboard layout cards style */
.warning-alert{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.check-status{
  color:var(--primary-color) !important;
}
.token-balance-card{
  background-color: var(--primary-color);
  min-height: 200px;
 
  /* max-width: 400px; */
  min-width: 350px;
  color:var(--white);
  padding:24px;
  border-radius: 10px;
}
.h-img{
  padding-right: 24px;
  padding-bottom: 24px;
  width: 75px !important;
}

.text-16{
  font-family: var(--poppins-font);
  color: #707070;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.text-16p{
  font-family: var(--poppins-font);
  color: #353535;
  font-size: 24px;
  
  font-weight: 600;
}
.text-16w{
  font-family: var(--poppins-font);
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.text-16b{
  font-family: var(--poppins-font);
  color: var(--primary-color);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.text-18{
  font-family: var(--poppins-font);
  font-size: 18px;
  font-weight: 700;
}
.text-32{
  color: var(--primary-color);
  font-family: var(--poppins-font);
  font-size: 32px;
  font-weight: 700;
}
.text-32b{
  color: #353535;
  font-family: var(--poppins-font);
  font-size: 32px;
  font-weight: 700;
}
.text-32w{
  color: var(--white);
  font-family: var(--poppins-font);
  font-size: 30px;
  font-weight: 700;
}
.sub-script-text{
  font-size: 14px;
}
.token-balance{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.edit-htc-token{
  padding-left: 250px;
  float: right;
  color:#8B8B8B;
}
.equivalent_usd{
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.transfer-img{
  width: 15px !important;
}
.token-balance-btn{
  color:var(--primary-color) !important;
  font-family: var(--poppins-font);
  font-size: 16px;
  background-color: var(--white) !important;
  border-radius: 20px;
  height: 45px;
  margin-left: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.token-balance-btn:hover{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
/* HOT FULL NODE*/
/*.token-calculation{
  font-family: var(--poppins-font);
  padding: 24px;
  height: 300px;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid #CACACA;
}*/
.hotfullnode{
  font-family: var(--poppins-font);
  padding: 24px;
  height: 300px;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid #CACACA;
margin: auto;
}
.hotfullnode-btn{
  background-color: var(--primary-color);
  border:1px solid var(--primary-color) !important;
  border-radius: 20px;
  color: var(--white);
}
.hotfullnode-btn :hover{
  background-color: transparent;
  color: var(--primary-color);
}

.token-converter-card{
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid rgba(202, 202, 202, 1);
  min-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 12px;
}
.token-converter{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.token-divider{
  background: #707070;
  opacity: 0.4;
  height: 175px;
}
.token-h-img{
  width: 50px !important;
}
.download-whitepaper-btn{
  background-color: var(--primary-color) !important;
  color:var(--white) !important;
  border-radius: 20px;
  border:1px solid var(--primary-color) !important;
  margin-top: 20px;
  height: 45px;
}

.table-card{
  border-radius: 10px;
  height: 310px;
  background: var(--white);
  color: var(--white);
  border: 1px solid #CACACA;
}
.transaction-card-table-wrapper {
  padding-bottom: 16px; /* Add some padding to the bottom to ensure the border is visible */
}

.transaction-card-table .ant-table {
  /* Ensure the table doesn't overflow the card */
  margin-bottom: 0;
}
/* footer section */
.ant-layout-footer {
  background-color:  var(--white);
  color:#707070;
  font-size: 16px;
  border: 1px solid #CACACA;
  text-align: center;
  border-radius: 10px;
  margin: 20px 30px;
}
/* Row-gap */
.row-gap{
  row-gap: 20px;
}


/* ----------------  table style ------------- */
.ant-card-head-title{
  font-size: 24px;
  font-weight: 600;
  color: #353535;
  font-family: var(--poppins-font);
}
.ant-card-head{
  border:none;
  margin: none;
}
.ant-card-extra > a{
  color:var(--primary-color) !important;
}
.ant-card-body{
  padding:0px 8px;
  
}
.transaction-card-table{
  height: 250px !important;
  overflow: scroll;
}
.ant-table{
  color:var(--white);
  font-family: var(--poppins-font);
  font-size: 16px;
  background-color:var(--white);
}
.ant-table-cell{
  padding: 12px !important;
  min-width: 120px;
}
.ant-table-thead > tr > th{
  background-color:var(--white);
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  border-top:1px solid #cacaca;
  border-bottom:1px solid #cacaca;
}
.ant-table-thead th.ant-table-column-has-sorters{
  transition:none;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -20px -16px;
  /* padding: 16px 16px; */
}
.ant-
.ant-table-tbody > tr > td{
  border:none;
  min-width: 150px;
  background-color: var(--white);
}
.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover{
  background-color: transparent;
}
.ant-empty-description{
  color:var(--blackColor);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td{
  background-color: transparent;
}
.pending-color{
  color: #DAD67A;
  font-size: 18px;
}
.success-color{
  color: #7ADAB8;
  font-size: 18px;
}
.failed-color{
  color: #F290A3;
  font-size: 18px;
}
.total-user-container{
  padding:16px;
}
.ant-radio-disabled + span{
  color:var(--white);
}
.ant-radio-disabled .ant-radio-inner{
  background-color: transparent;
  border-color: var(--primary-color) !important;
}
.ant-radio-disabled .ant-radio-inner::after{
  background-color: var(--primary-color);
}
.pending-kyc{
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: center;
}
.user-verify-button{
  border:1px solid var(--primary-color) !important;
  background-color: transparent !important;
  color:var(--primary-color) !important;
  border-radius: 20px !important;
  height: 35px;
  width: 120px;
}
.user-tab-container{
  display:flex;
  gap:15px;
  justify-content:space-between;
  flex-wrap:wrap;
}
.search-input{
  background-color:var(--white) !important;
  border-radius: 5px;
  height: 35px;
  width: 300px;
  border-color: #888888;
  color: var(--white);
}
.user-verify-button:hover{
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
}
.user-manage-verify-button{
  border:1px solid var(--primary-color) !important;
  background-color: transparent !important;
  color:var(--primary-color) !important;
  border-radius: 10px !important;
  height: 35px;
  width: 120px;
}
.user-manage-verify-button:hover{
  background-color: var(--primary-color) !important;
  color:var(--white) !important;
}



/* Token calculation  */
.text-24{
  font-size: 24px;
  font-weight: 600;
  color: #353535;
}
.text-12{
  font-size: 14px;
  color: #707070 !important;
}
.text-12o{
  font-size: 12px;
  color: #707070 !important;
}
.text-12p{
  font-size: 14px;
  color: var(--primary-color) !important;
}
.token-calculation{
  font-family: var(--poppins-font);
  padding: 24px;
  height: 310px;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid #CACACA;
}
.token-calculation-input{
  padding:0px 0px 0px 15px;
  background: var(--white) !important;
  width: 60%;
  height: 45px;
  border:2px solid var(--primary-color);
  border-radius: 20px;
}
.token-calculation-input > .ant-input{
  background-color: var(--white) !important;
}
/* remove increament and decreament button from number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}
.token-calculation-dropdown{
  width: 100px;
}
.token-calculation-dropdown > .ant-select-selector {
  background-color: #2F2F2F !important;
  border:2px solid #2F2F2F !important;
  border-radius: 20px !important;
}
.fixed-span{
  color: var(--primary-color);
}
.token-sale-chart-card{
  background:var(--white) !important;
  border:1px solid #cacaca;
  border-radius: 10px;
  height: 500px;
}
.token-sale-chart-card-body{
  padding: 24px;
}
.token-sale-select{
  width:100px;
  background:var(--primary-color) !important;
  border-radius:5px;
  color: var(--white);
}
.ant-select.ant-select-in-form-item{
  border:1px solid var(--primary-color)
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border:none;
}
.charts-loading-container{
  background-color: var(--white) !important;
  z-index:1 !important;
}
.sales-progress{
  background-color: var(--white);
  border: 1px solid #CACACA;
  border-radius: 10px;
  height: 500px;
}
.sales-progress-padding{
  padding: 16px;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
.sales-progress-text-14{
  font-family: var(--poppins-font);
  font-size: 14px;
  color:#8c8c8c;
}
.ant-slider-disabled .ant-slider-track {
  background-color: var(--primary-color) !important; 
}
.sales-box-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center,
}
.sales-counter-box{
  height: 100px;
  width: 80px;
  font-size: 14px;
  background-color: transparent;
  border: 2px solid #2F2F2F;
  border-radius: 15px;
  color:var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
}

/* Transaction */
.transaction-card{
  border-radius: 10px;
  background: var(--white);
  color: var(--white);
  border: 1px solid #CACACA;
  height: 750px;
}
.ant-table-pagination > * {
  background: var(--white);
  border: 1px solid var(--primary-color);
  border-radius: 50px;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before{
  border:none;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link{
  background-color: var(--white);
  border:1px solid var(--primary-color);
  color:black;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  background-color: var(--white);
  border:none;
  border-radius: 50px;
  color:#707070;
}
.ant-pagination-item >*{
  color:#707070 !important;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{
  opacity: unset;
  color:var(--primary-color)
}
/* table sorter style */
.ant-table-column-sorters{
  background-color: var(--white);
  content: unset;
}
.ant-table-thead th.ant-table-column-sort{
  background-color: var(--white) !important;
}
.ant-table-thead :hover{
  background-color: var(--white) !important;
}
.ant-pagination-item-active > a{
  color:var(--primary-color) !important; 
}
.ant-table-pagination.ant-pagination{
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
/* -----  Profile page ---- */
.kyc-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 300px; 
  padding: 24px 36px 48px;
  background: var(--white);
  border: 1px solid #CACACA;
  border-radius: 10px 150px 150px 10px;
}
.kyc-info-container > .application-data{
  order:1;
}
.kyc-info-container > .kyc-hr{
  order:2;
}
.kyc-info-container > .identity-doc{
  order:3;
}
.kyc-info-container > .kyc-identity{
  order:4;
}
.kyc-info-container > .process-image{
  order:5;
}
.kyc-title-container{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  gap: 80px;
}
.kyc-hr{
  margin-top: 30px;
  height: 100%;
  border-color:#cacaca;
}
.kyc-identity{
  margin-top: 30px;
  height: 0px;
  border-color:var(--greyColor);
}
.kyc-process-image {
  border-radius: 100%;
  background: black;
  padding: 30px; 
  margin-top: 12px;
  width: 230px;
  height: 230px; 
  align-items:center;
}
.pending-color-button{
  color:black;
  background-color: #DAD67A;
  border-radius: 8px;
  padding: 3px 15px;
  margin: auto;
}
.success-color-button{
  color:black;
  background-color: #7ADAB8;
  border-radius: 8px;
  padding: 3px 15px;
  margin: auto;
  font-weight: 500;
}
.failed-color-button{
  color:black;
  background-color: #F290A3;
  border-radius: 8px;
  padding: 3px 15px;
  margin: auto;
  font-weight: 500;
}

.ant-collapse-content > .ant-collapse-content-box{
  background-color: var(--white) !important;
  padding:0px 20px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  color:#353535;
}
.profile-personal-info{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--white);
  border-radius: 10px;
  padding: 32px 48px;
  border: 1px solid #CACACA;
  align-items: center;
}
.profile-avtar-photo{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  background-color:transparent;
  box-shadow: 0px 0px 20px 0px var(--primary-color);
}
.profile-user-name{
  color: #707070;
  font-size: 40px;
  font-weight: 700;
}
.profile-text-color{
  color:#888888;
}
.proifle-logout-button{
  height: 40px;
  width: 150px;
  border: 1px solid #FD5353 !important;
  border-radius: 20px;
  color: #FD5353 !important;
  background:transparent !important;
}
.proifle-logout-button:hover{
  color: var(--white) !important;
  background-color: #FD5353 !important;
}
.edit-profile{
  margin: none;
  padding: none;
}
.change-pass-container{
  padding: 16px 0px;
  background-color: var(--white) !important;
}
.change-password{
  border: 1px solid var(--greyColor);
  background: var(--white);
  border-radius: 10px;
  border: solid 1px #CACACA;
  padding: 5px 24px;
}
.change-password-label{
  color:#707070;
  display: block;
}
.change-password-input{
  background-color: var(--white) !important;
  border:1px solid #888888 !important;
  border-radius: 7px !important;
  width: 400px;
}
.change-password-button{
  width: 200px;
  align-items:flex-end;
}
.change-password-button:hover{
  background-color: transparent !important;
  border:1px solid var(--primary-color) !important;
  color:var(--primary-color) !important;
}
.border-none{
  border:none !important;
}
/*  Manage user Style */
.manage-user-container{
  border-radius: 10px;
  background: var(--white);
  color: var(--white);
  border: 1px solid #CACACA;
}
.manage-user-body{
  padding:0px 25px
}
.manage-user-profile{
  display: flex;
  justify-content: space-between;
  padding:25px 0px ;
}
.manage-user-avatar{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color:transparent;
  box-shadow: 0px 0px 20px 0px var(--primary-color);
}
.ban-profile-button{
  height: 35px;
  width: 150px;
  background-color: var(--white) !important;
  border-color:#F290A3 !important;
  color:#F290A3 !important;
  border-radius: 10px;
}
.ban-profile-button:hover{
  background-color: #F290A3 !important;
  color:var(--white) !important;
}
.unlock-profile-button{
  height: 35px;
  width: 150px;
  background-color: var(--white) !important;
  border-color:#7ADAB8 !important;
  color:#7ADAB8 !important;
  border-radius: 10px;
}
.unlock-profile-button:hover{
  background-color: #7ADAB8 !important;
  color:var(--white ) !important;
}
.manage-user-kyc-info{
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
}
.kyc-verify-hr{
  height: 225px;
  border:1px solid #888888;
}
.manage-user-transaction-table{
  max-height: 750px !important;
  overflow: scroll;
}
.approve-button{
  border: 1px solid #7ADAB8 !important;
  border-radius: 10px;
  background-color: transparent !important;
  color: #7ADAB8 !important;
}
.approve-disabled-button{
  border: 1px solid #7ADAB8 !important;
  opacity: 0.5;
  border-radius: 10px;
  background-color: transparent !important;
  color: #7ADAB8 !important;
}
.reject-button{
  border: 1px solid #F290A3 !important;
  border-radius: 10px;
  background-color: transparent !important;
  color: #F290A3 !important;
}
.reject-disabled-button{
  border: 1px solid #F290A3 !important;
  opacity: 0.5;
  border-radius: 10px;
  background-color: transparent !important;
  color: #F290A3 !important;
}
.ant-modal-confirm-body .ant-modal-confirm-title{
  color:var(--white) !important;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
  color:var(--white) !important;
}


/*  slider for mobile code */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: var(--white);
  box-shadow:0 10px 20px rgba(0, 0, 0, 0.50) !important;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidenav-visible{
  width: 250px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: var(--white);
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: var(--primary-color);
}

.sidenav .closebtn {
  position: absolute;
  top:30px;
  font-size: 25px !important; 
  color:#707070;
  right: 25px;
  margin-left: 50px;
}
  
.buy-htc-coin {
  background-color: var(--primary-color) !important;
  color: white !important;
  border:1px solid var(--primary-color) !important;
  border-radius: 20px;
  height: 35px;
  
}
.success-buy-htc-coin {
  background-color: #7ADAB8 !important;
  color: black !important;
  border:1px solid #7ADAB8 !important;
  border-radius: 10px;
  height: 35px;
  min-width: 200px;
}

.success-buy-htc-coin:hover {
  background-color: transparent !important;
  color: #7ADAB8 !important;
  box-shadow: 0px 0px 15px 0px #7ADAB8;
}
.buy-htc-coin:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  box-shadow: 0px 0px 15px 0px var(--primary-color);
}

.currency-option-select{
  border-radius: 5px;
}
.qrcode-container{
  height:250px;
  width:250px;
  border-radius: 10px;
  background-color: var(--white); 
  padding: 20px;
  margin: auto;
}

.notes-text > li{
  list-style-type: disc;
  list-style-position: inside;
}

/*  finish code */

/* *******************  Mobile screen ************** */
@media only screen and (max-width: 480px){

  /* header */
  .site-layout-background {
    height: 80px !important;
    padding: 20px;
  }
  .abs-header{
    height: 80px;
  }
  .header-right-collapse{
    width:100%;
    justify-content: space-between;
    align-items:center;
  }
  .menu {
    font-size: 18px;
  }
  .logo {
    padding: 25px;
    min-height: 100px !important;
  }
  .haveto-mobile-img{
    width: 120px;
  }
  /* collapse button */
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    font-size: 14px;
  }
  .ant-modal-title {
    font-size: 20px;
  }
  
  .ant-menu > * {
    font-size: 14px;
  }
  .ant-menu-item-icon > svg{
    font-size: 14px !important;
  }
  /* aside */
  aside{
    display: none;
  }
  /* layout */
  .ant-layout-content {
    font-size: 14px;
  }
  /* dashboard layout style */
  .token-converter-card{
    flex-direction: column;
    padding: 15px;
  }
  .divider-visiblity{
    display: none;
  }
  .token-divider{
    height: 1px;
    margin: 20px;
  }
  .token-converter{
    align-items: center !important;
  }
  .token-balance-btn{
    margin-top: 10px;
    height: 40px;
    width: 100%;
    font-size: 13px;
    margin-left: 0px;
  }
  .text-16{
    font-size: 14px;
  }
  .text-18{
    font-size: 16px;
  }
  .text-32{
    font-size: 24px;
  }
  .text-32b{
    font-size: 24px;
  }
  .text-32w{
    font-size: 24px;
  }
  .sub-script-text{
    font-size: 12px;
  }
  .trigger{
    display: none;
  }
  .token-balance{
    flex-direction: row;
  }
  .token-balance-card{
    min-width: 100%;
    padding: 15px;
  }
  .ant-layout-content{
    margin: 0px 15px !important ;
  }
  .download-whitepaper-btn{
    height: 35px;
    width: 100%;
    overflow: hidden;
    font-size: 12px;
  }
  .warning-alert{
    flex-direction: column;
    align-items: flex-start;
  }
  .check-status{
    margin-top:10px;
  }
  /* footer */
  .ant-layout-footer {
    font-size: 14px;
    margin: 10px 15px;
  }
  /* Token calculation  */
  .token-calculation{
    padding: 15px;
  }
  .token-calculation-input{
    width: 100%;
    background-color: transparent;
  }
  .token-sale-chart-card-body{
    padding: 15px;
  }
  /* sales-progress */
  .sales-progress-padding{
    padding: 7px;
  }
  .sales-progress-text-14{
    font-size: 12px;
  }
  .sales-slider{
    padding: 2px;
    overflow-x: hidden;
  }
  .sales-counter-box{
    height: 80px;
    width: 50px;
    font-size: 12px;
    border-radius: 10px;
  }
  .transaction-card{
    height: 800px;
  }
  /* KYC verification */
  .text-24{
    font-size: 16px;
    color: #353535;
  }
  .ant-card-head-title{
    font-size: 16px;
  }
  .kyc-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    border-radius: 10px;
    gap: 10px;
  }
  .kyc-info-container > .application-data{
    order:2;
  }
  .kyc-info-container > .identity-doc{
    order:3;
  }
  .kyc-info-container > .process-image{
    order:1;
  }
  .kyc-title-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    gap: 0px;
  }
  .kyc-hr{
    width: 100%;
  }
  .kyc-process-image{
    width: 130px;
    margin: auto;
    height: 130px; 
  }
  .kyc-button{

    width:60px;
    padding: 1px;
    font-size: 14px;
  }
  .kyc-button > span > svg{
    display: none;
  }
  /* profile page */
  .profile-personal-info{
    gap:30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: var(--white);
    border-radius: 10px;
    padding: 15px;
  }
  .profile-avtar-photo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background-color:transparent;
    box-shadow: 0px 0px 20px 0px var(--primary-color);
  }
  .profile-user-name{
    font-size: 22px;
    font-weight: 700;
  }
  .proifle-logout-button{
    display: none;
  }
  .change-password{
    padding: 0px;
  }
  .change-password-label{
    display: none;
  }
  .change-password-input{
    width: 100%;
  }
  .change-password-button{
    width: 100%;
    max-width: 250px;
  }
  /* manage user */
  .manage-user-body{
    padding:0px 8px
  }
  .manage-user-profile{
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .manage-user-avatar{
    height: 60px;
    width: 60px;
  }
  .ban-profile-button{
    width: 140px;
  }
  .manage-user-kyc-info{
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .kyc-verify-hr{
    height: 0px;
    width: 100%;
  }
}
@media only screen and (min-width: 660px) and (max-width: 767px) {
  .token-converter-card{
    flex-direction: row !important;
  }
  .divider-visiblity{
    display: none;
  }
  .token-divider{
    height: 180px !important;
    margin: 0px !important;
  }
  .change-password-label{
    display: block !important;
  }
  .manage-user-kyc-info{
    display: flex;
    gap: 20px;
    flex-direction: row !important;
  }
  .kyc-verify-hr{
    height: 225px !important;
    width: 0px !important;
  }
}
/* Media screen for tablet */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  /* aside-sidebar */
  aside{
    display: none;
  }
  /* header */
  .site-layout-background {
    height: 100px !important;
    padding: 20px;
  }
  .ant-modal-title {
    font-size: 25px;
  }
  
  .abs-header{
    height: 100px;
  }
  .header-right-collapse{
    width:calc(100% - 0px)
  }
  .header-right-collapse{
    width:100%;
    justify-content: space-between;
    align-items:center;    
  }
  .haveto-mobile-img{
    width: 150px;
  }
  .menu {
    font-size: 24px;
  }
  /* layout */
  .ant-layout-content{
    margin: 0px 20px !important ;
  }
  /* dashboard layout style */
  .token-converter-card{
    flex-direction: column;
  }
  .divider-visiblity{
    display: none;
  }
  .token-divider{
    height: 1px;
    margin: 30px;
  }
  .token-converter{
    align-items: center !important;
  }
  .token-balance-btn{
    width: 250px;
    margin-left: 0px;
  }
  .text-16{
    font-size: 16px;
  }
  .text-32{
    font-size: 32px;
  }
  .text-32b{
    font-size: 24px;
  }
  .text-32w{
    font-size: 24px;
  }
  .trigger{
    display: none;
  }
  .token-balance{
    flex-direction: row;
  }
  .token-balance-card{
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   /* KYC verification */
   .text-24{
    font-size: 18px;
  }
  .ant-card-head-title{
    font-size: 18px;
  }
  .kyc-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    border-radius: 10px;
    gap: 15px;
  }
  .kyc-info-container > .application-data{
    order:2;
  }
  .kyc-info-container > .identity-doc{
    order:3;
  }
  .kyc-info-container > .process-image{
    order:1;
  }
  .kyc-title-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    gap: 0px;
  }
  .kyc-hr{
    width: 100%;
  }
  .kyc-process-image{
    height: 150px;
    width: 150px;
    margin: auto;
  }
  .kyc-button{
    width:100px;
    padding: 1px;
    font-size: 14px;
  }
  /* profile page */
    .profile-personal-info{
      gap:30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 15px;
    }
    .profile-avtar-photo{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 80px;
    }
    .profile-user-name{
      font-size: 26px;
      font-weight: 700;
    }
    .proifle-logout-button{
      display: none;
    }
    .change-password{
      padding: 0px;
    }
    .change-password-label{
      display: none;
    }
    .change-password-input{
      width: 100%;
    }
    .change-password-button{
      width: 100%;
      max-width: 250px;
    }
    /* manage user */
    .manage-user-body{
      padding:0px 10px
    }
    .ban-profile-button{
      width: 130px;
    }
    .manage-user-kyc-info{
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
    .kyc-verify-hr{
      height: 0px;
      width: 100%;
    }
}
/* Media screen for small laptop */
@media only screen and (min-width: 768px) and (max-width:991px) {
  /* layout */
  .ant-layout-content{
    margin: 0px 20px !important;
  }
  /* dashboard layout style */
  .token-converter-card{
    flex-direction: column;
  }
  .divider-visiblity{
    display: none;
  }
  .token-divider{
    height: 1px;
    margin: 30px;
  }
  .token-converter{
    align-items: center !important;
  }
  .token-balance-btn{
    width: 250px;
    margin-left: 0px;
  }
  .text-16{
    font-size: 16px;
  }
  .text-32{
    font-size: 32px;
  }
  .text-32b{
    font-size: 26px;
  }
  .text-32w{
    font-size: 26px;
  }
  .trigger{
    display: none;
  }
  .token-balance{
    flex-direction: row;
  }
  .token-balance-card{
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   /* KYC verification */
   .text-24{
    font-size: 18px;
  }
  .ant-card-head-title{
    font-size: 18px;
   
  }
  .kyc-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    border-radius: 10px;
  }
  .kyc-title-container{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    gap: 30px;
  }
  .kyc-hr{
   
      display: none;
  }
  .kyc-process-image{
    display: none;
  }
  .kyc-button{
    width:100px;
    padding: 1px;
    font-size: 14px;
  }
  /* profile page */
  .profile-personal-info{
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: flex-start;
    justify-content: space-between;
    background: var(--white);
    border-radius: 10px;
    padding: 30px;
  }
  .profile-avtar-photo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
  }
  .profile-user-name{
    font-size: 28px;
    font-weight: 700;
  }
  .proifle-logout-button{
    display: none;
  }
  .change-password{
    padding: 0px;
  }
  .change-password-input{
    width: 100%;
  }
  .change-password-button{
    width: 100%;
    max-width: 250px;
  }
  .manage-user-body{
    padding:0px 12px
  }
  .manage-user-kyc-info{
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .kyc-verify-hr{
    height: 0px;
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width:1200px) {
  .token-balance-card{
    min-width: 250px;
  }
  .ant-modal-title {
    font-size: 30px;
  }
  
  .strong{
    font-size: 12px;
  }
  .token-balance-btn{
    width: 100%;
    margin-left: 0px;
font-size: 15px;
  }
  .text-32{
    font-size: 24px;
  }
  .text-32b{
    font-size: 32px;
  }
  .text-32w{
    font-size: 30px;
  }
  .trigger{
    display: none;
  }
  .token-calculation{
    padding: 16px;
  }
  .token-calculation-input{
    width: 100%;
  }
  /* KYC verification */
    .text-24{
      font-size: 20px;
    }
    .ant-card-head-title{
      font-size: 20px;
      
    }
    .kyc-info-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      padding: 24px;
      border-radius: 10px;
    }
    .kyc-title-container{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      gap: 30px;
    }
    .kyc-hr{
        margin-top: 30px;
        height: 200px;
        border-color:#cacaca;
    }
    .kyc-process-image{
      display: none;
    }
    .kyc-button{
      width:100px;
      padding: 1px;
      font-size: 14px;
    }
  /* profile page */
  .profile-personal-info{
      padding: 24px;
  }
  .profile-avtar-photo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
  }
  .profile-user-name{
    font-size: 30px;
    font-weight: 700;
  }
  .change-password{
    padding: 4px;
  }
}
@media only screen and (min-width: 1201px) and (max-width:1440px) {
  .h-img{
    width: 80px !important;
  }
  .strong{
    font-size: 13px;
  }
  .token-balance-card{
    padding: 12px;
    min-width: 250px;
  }
  .token-balance-btn{
    width: 100%;
    margin:10px 0px 0px 0px;
  }
  .text-32{
    font-size: 24px;
  }
  .text-32b{
    font-size: 32px;
  }
  .text-32w{
    font-size: 30px;
  }
  .token-calculation{
    padding: 16px;
  }
  .token-calculation-input{
    width: 100%;
  }
  /* KYC verification */
  .text-24{
    font-size: 24px;
  }

  .kyc-info-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 32px;
    border-radius: 10px;
  }
  .kyc-hr{
    margin-top: 30px;
    height: 200px;
    border-color:#cacaca;
}
  .kyc-title-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    gap: 30px;
  }
  .kyc-process-image{
    display: none;
  }
  .kyc-button{
    width:100px;
    padding: 1px;
    font-size: 14px;
  }
  /* profile page */
  .profile-personal-info{
    padding: 32px;
  }
  .profile-avtar-photo{
    height: 120px;
    width: 120px;
  }
  .profile-user-name{
    font-size: 32px;
    font-weight: 700;
  }
  .manage-user-body{
    padding:0px 15px
  }
}
@media only screen and (min-width: 1441px) and (max-width:1700px) {
  .token-balance-card{
    padding: 24px;
  }
  .token-balance-card{
    min-width: 300px;
  }
  .text-32{
    font-size: 32px;
  }
  .text-32b{
    font-size: 32px;
  }
  .text-32w{
    font-size: 30px;
  }
  .token-calculation-input{
    width: 100%;
  }
  .manage-user-body{
    padding:0px 20px
  }
}
@media only screen and (min-width: 1940px){
  .h-img{
    width: 120px !important;
  }
  .token-balance-card{
    padding: 30px;
    min-height: 300px;
  }
  .token-balance-btn{
    width: 100%;
    margin:10px 0px 0px 0px;
  }
  .text-32{
    font-size: 32px;
  }
  .token-calculation{
    padding: 30px;
  }
  .token-calculation-input{
    width: 100%;
  }
  /* KYC verification */
  .text-24{
    font-size: 24px;
  }
  /* layout */
  .ant-layout-content{
    margin: 0px 20px !important;
  }
  /* dashboard layout style */
  .token-converter-card{
    min-height: 300px;
  }
  .token-divider{
    height: 250px;
  }
  .token-balance-btn{
    width: 300px;
    margin-left: 0px;
  }
  .text-16{
    font-size: 20px;
  }

  /* profile page */
  .profile-personal-info{
    padding: 50px;
  }
  .profile-avtar-photo{
    height: 120px;
    width: 120px;
  }
  .profile-user-name{
    font-size: 32px;
    font-weight: 800;
  }
  .proifle-logout-button{
    width: 250px;
    height: 60px;
    font-size: 28px;
  }
  .change-password{
    padding: 20px;
  }
  .change-password-input{
    width: 100%;
  }
  .change-password-button{
    height: 50px;
    width: 350px;
    font-size: 24px;
  }
}